import React from 'react';
import { Container, Grid, Typography, List, ListItem, ListItemText, ListItemIcon } from '@mui/material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'; // Bullet icon

const TermsOfService = () => {
  return (
    <Container sx={{ paddingTop: 5, paddingBottom: 10 }}>
      <Typography variant="h3" component="h1" gutterBottom sx={{ marginBottom: 6, fontFamily: 'Matura MT Script Capitals' }}>
        Terms of Service
      </Typography>
      <Grid container spacing={4} sx={{textAlign:'left'}}> 
        <Grid item xs={12}>
          <Typography variant="h5" component="h2" gutterBottom>
            1. Acceptance of Terms
          </Typography>
          <Typography paragraph>
            By accessing and using [Online Academy Name], you agree to comply with and be bound by these Terms of Service. Please review the following terms carefully. If you do not agree with these terms, you should not use our services.
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h5" component="h2" gutterBottom>
            2. Changes to the Terms
          </Typography>
          <Typography paragraph>
            We reserve the right to modify these Terms of Service at any time. Any changes will be posted on this page, and your continued use of the site after the changes become effective constitutes your acceptance of the new terms.
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h5" component="h2" gutterBottom>
            3. User Responsibilities
          </Typography>
          <Typography paragraph>
            As a user of our platform, you agree to the following responsibilities:
          </Typography>
          <List>
            <ListItem>
              <ListItemIcon>
                <FiberManualRecordIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Provide accurate and up-to-date information when registering or using our services." />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <FiberManualRecordIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Maintain the security and confidentiality of your account information." />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <FiberManualRecordIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Comply with all applicable laws and regulations while using our platform." />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <FiberManualRecordIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Refrain from using the platform for any unlawful or prohibited activities." />
            </ListItem>
          </List>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h5" component="h2" gutterBottom>
            4. Intellectual Property
          </Typography>
          <Typography paragraph>
            All content, including but not limited to text, graphics, logos, and software, is the property of [Online Academy Name] or its licensors and is protected by applicable copyright, trademark, and intellectual property laws. You agree not to copy, modify, or distribute any content without prior written consent.
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h5" component="h2" gutterBottom>
            5. Termination of Service
          </Typography>
          <Typography paragraph>
            We reserve the right to terminate or suspend your account and access to our services at any time, without notice or liability, if you violate these Terms of Service or engage in behavior that is harmful to our platform or users.
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h5" component="h2" gutterBottom>
            6. Limitation of Liability
          </Typography>
          <Typography paragraph>
            To the maximum extent permitted by law, [Online Academy Name] shall not be liable for any indirect, incidental, or consequential damages arising from your use of the platform, including but not limited to loss of data, revenue, or business opportunities.
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h5" component="h2" gutterBottom>
            7. Governing Law
          </Typography>
          <Typography paragraph>
            These Terms of Service shall be governed by and construed in accordance with the laws of [Your Jurisdiction]. Any disputes arising out of or relating to these terms shall be resolved in the courts of [Your Jurisdiction].
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h5" component="h2" gutterBottom>
            8. Disclaimer of Warranties
          </Typography>
          <Typography paragraph>
            Our platform is provided "as is" and "as available" without any warranties of any kind, either express or implied, including but not limited to implied warranties of merchantability, fitness for a particular purpose, or non-infringement.
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h5" component="h2" gutterBottom>
            9. User Conduct
          </Typography>
          <Typography paragraph>
            While using our platform, you agree to:
          </Typography>
          <List>
            <ListItem>
              <ListItemIcon>
                <FiberManualRecordIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Not engage in any unlawful, fraudulent, or malicious activities." />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <FiberManualRecordIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Not upload or transmit harmful content such as viruses or malware." />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <FiberManualRecordIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Respect the intellectual property rights of others." />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <FiberManualRecordIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Not attempt to gain unauthorized access to the platform or its systems." />
            </ListItem>
          </List>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h5" component="h2" gutterBottom>
            10. Contact Information
          </Typography>
          <Typography paragraph>
            If you have any questions or concerns regarding these Terms of Service, please contact us at silvia@weberlegalnurse.com.
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
};

export default TermsOfService;
