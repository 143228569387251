import React, { useEffect, useState } from 'react';
import AxiosInstance from './Axios'; // Import your Axios instance
import { useParams } from 'react-router-dom';
import { Container, Typography, Card, CardContent, Box, Button, CircularProgress } from '@mui/material';

const Exam = () => {
  const { id } = useParams();
  const [examTitle, setExamTitle] = useState('');
  const [questions, setQuestions] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [userSelections, setUserSelections] = useState([]);
  const [score, setScore] = useState(0);
  const [showScore, setShowScore] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingCertificate, setLoadingCertificate] = useState(false); // Add this state

  useEffect(() => {
    const fetchExamDetails = async () => {
      try {
        const response = await AxiosInstance.get(`/Academy/api/course/examination/${id}/`);
        setExamTitle(response.data.title);
        setQuestions(response.data.questions);
        setUserSelections(new Array(response.data.questions.length).fill(''));
        setLoading(false);
      } catch (error) {
        console.error('Error fetching exam details:', error);
        setLoading(false);
      }
    };

    fetchExamDetails();
  }, [id]);

  const handleOptionChange = (event) => {
    const updatedSelections = [...userSelections];
    updatedSelections[currentQuestion] = event.target.value;
    setUserSelections(updatedSelections);
  };

  const evaluateScore = () => {
    let currentScore = 0;
    userSelections.forEach((selection, index) => {
      if (selection === questions[index]?.answer) {
        currentScore += 1;
      }
    });
    setScore(currentScore);
  };

  const handleNextQuestion = () => {
    const nextQuestion = currentQuestion + 1;
    if (nextQuestion < questions.length) {
      setCurrentQuestion(nextQuestion);
    } else {
      setShowScore(true);
      evaluateScore();
    }
  };

  const handlePreview = () => {
    const prevQuestion = currentQuestion - 1;
    if (prevQuestion >= 0) {
      setCurrentQuestion(prevQuestion);
    }
  };

  const submitScore = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      console.error('No token found');
      return;
    }

    try {
      const response = await AxiosInstance.post('/Academy/api/course/examination/submit/score/', 
      {
        exam_id: id,
        score: score,
        total: questions.length
      }, 
      {
        headers: {
          Authorization: `Token ${token}`
        }
      });

      console.log('Score submitted successfully:', response.data);
    } catch (error) {
      console.error('Error submitting score:', error);
    }
  };

  const requestCertificate = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      console.error('No token found');
      return;
    }

    setLoadingCertificate(true); // Start loading spinner

    try {
      const response = await AxiosInstance.post(`/Academy/api/course/examination/request/certificate/${id}`, 
      {
        exam_id: id
      }, 
      {
        headers: {
          Authorization: `Token ${token}`
        }
      });

      console.log('Certificate requested successfully:', response.data);
      if (response.data.message === 'SUCCESS') {
        window.location.href = '/MyAcademy/';
      }
    } catch (error) {
      console.error('Error requesting certificate:', error);
    } finally {
      setLoadingCertificate(false); // Stop loading spinner
    }
  };

  useEffect(() => {
    if (showScore) {
      submitScore();
    }
  }, [showScore]);

  if (loading) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <Container sx={{ padding: 5, marginBottom: 5 }}>
      <Typography variant="h3" component="h1" gutterBottom sx={{ marginBottom: 6 }}>
        <span style={{ fontFamily: 'Matura MT Script Capitals' }}>{examTitle}</span>
      </Typography>
      <Card sx={{ backgroundColor: '#f1f7ff', marginBottom: 3 }}>
        <CardContent>
          <div className="exam">
            {showScore ? (
              <div className="score-section">
                <h1 className="exam-result-header">Examination Result</h1>
                <p>You scored {score} out of {questions.length}</p>
                <p>Average score: {((score / questions.length) * 100).toFixed(2)}%</p>
                <small style={{fontSize:17}}>You can only request certificate if your average score is more than 80%</small><br></br>
                <small style={{fontSize:16}}>Certificate will be sent to your E-mail</small>
                {((score / questions.length) * 100) >= 80 && (
                  <Box sx={{ marginTop: 3 }}>
                    <Button 
                      variant="contained" 
                      color="primary" 
                      onClick={requestCertificate}
                      disabled={loadingCertificate} // Disable button while loading
                      endIcon={loadingCertificate && <CircularProgress size={24} />}
                    >
                      {loadingCertificate ? 'Requesting Certificate...' : 'Request Certificate'}
                    </Button>
                  </Box>
                )}
                <Box sx={{ marginTop: 3 }}>
                  <Button 
                    variant="contained" 
                    color="primary" 
                    onClick={() => window.location.reload()}
                  >
                    Retake Exam
                  </Button>
                </Box>
              </div>
            ) : (
              <div className="question-section">
                <div className="question-count">
                  <span>Question {currentQuestion + 1}</span>/{questions.length}
                </div>
                {questions.length > 0 && (
                  <div className="question-text">{questions[currentQuestion].question}</div>
                )}
                <div className="options-section">
                  <p>Choose your answer.</p>
                  {questions[currentQuestion]?.options.map((option) => (
                    <label key={option}>
                      <input
                        type="radio"
                        name="option"
                        value={option}
                        checked={userSelections[currentQuestion] === option}
                        onChange={handleOptionChange}
                      />
                      {option}
                    </label>
                  ))}
                </div>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 5 }}>
                  <Button
                    variant="contained"
                    onClick={handlePreview}
                    disabled={currentQuestion === 0}
                  >
                    Preview
                  </Button>
                  <Button
                    variant="contained"
                    onClick={handleNextQuestion}
                    disabled={!userSelections[currentQuestion]}
                  >
                    {currentQuestion === questions.length - 1 ? "Submit" : "Next"}
                  </Button>
                </Box>
              </div>
            )}
          </div>
        </CardContent>
      </Card>
    </Container>
  );
};

export default Exam;
