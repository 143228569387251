import * as React from 'react';
import TextField from '@mui/material/TextField';
import { Controller } from 'react-hook-form';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

export default function MyTextFields(props) {
    const { label, width, placeholder, name, control, type = 'text', rules } = props;
    const [showPassword, setShowPassword] = React.useState(false);

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
        <Controller
            name={name}
            control={control}
            rules={rules}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                    sx={{ width: width, marginBottom:2 }}
                    onChange={onChange}
                    value={value}
                    id="outlined-basic"
                    label={label}
                    variant="outlined"
                    placeholder={placeholder}
                    type={type === 'password' && !showPassword ? 'password' : 'text'}
                    error={!!error}
                    helperText={error ? error.message : ''}
                    InputProps={{
                        endAdornment: type === 'password' ? (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        ) : null,
                    }}
                />
            )}
        />
    );
}
