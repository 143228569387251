import React, { useState, useEffect } from 'react';
import AxiosInstance from './Axios'; // Import your Axios instance
import { useParams } from 'react-router-dom';
import { Link, Navigate  } from 'react-router-dom';
import { Typography, Card, CardContent, Grid, CardMedia, Box, Button } from '@mui/material';

import StripeContainer from './forms/PaymentDocumentForm';
import { styled } from '@mui/system';
import djangoURL from './GlobalVariables'


const MyDocumentDetails = () => {
  const { id } = useParams();
  const [course, setCourse] = useState(null);
  const [loading, setLoading] = useState(true);
  const isLoggedIn = localStorage.getItem('token') !== null;
  console.log(isLoggedIn)
  const Container = styled('div')({
    '@media (min-width: 1200px)': {
      maxWidth: '100%',
    },
  });


  useEffect(() => {
    const fetchCourseDetails = async () => {
      const token = localStorage.getItem('token'); // Retrieve the token
      if (!token) {
        console.error('No token found');
        return;
      }
      try {
        const response = await AxiosInstance.get(`/Academy/api/mydocument/${id}/`, {
          headers: {
            Authorization: `Token ${token}` // Attach the token in the Authorization header
          }
        });
        
        setCourse(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching docs details:', error);
        setLoading(false);
      }
    };

    fetchCourseDetails();
  }, [id]); // Depend on id since it's used in the URL

  if (loading) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <Container sx={{ padding: 5, paddingBottom: 50 }}>
      <Grid container spacing={5}>
        <Grid item xs={12} md={8}>

              <Grid sx={{ paddingBottom: 3 }}>


                  <Typography variant="h4" component="h4" sx={{ textAlign: 'center', color: '#1976d2' }}>
                    {course.document_title}
                  </Typography>
                  <Typography sx={{ textAlign: 'center' }}>
                    {course.document_short_description}
                  </Typography>
                  
              </Grid>
     

          <Card sx={{marginBottom: 3 }}>
            <CardContent>
              <Typography variant="h5" sx={{marginBottom: 1, textAlign:'left', fontWeight: 'bold'}}>Document Description:</Typography>
              <Typography sx={{textAlign:'left', fontSize: 18}}>
                {course.document_full_description}
              </Typography>
            </CardContent>
          </Card>
          
        </Grid>

        <Grid item xs={12} md={4}>
          <Box sx={{ position: 'sticky', top: 100 }}>
          {course.isPurchased ? (
              !isLoggedIn ? (
                <Card sx={{ marginBottom: 3 }} className="card-element">
                  <CardContent>
                    <Typography variant="h6" sx={{ padding: 5, textAlign: 'center', fontWeight: 'bold' }}>
                      Please Login or Register to buy this course.
                    </Typography>
                    <Button sx={{ width: '100%' }} variant="contained" component={Link} to="/Login">
                      Login or Register
                    </Button>
                  </CardContent>
                </Card>
              ) : (
                <Card sx={{ marginBottom: 3 }} className="card-element">
                  <CardContent>
                    <Typography variant="h6" sx={{ padding: 5, textAlign: 'center', fontWeight: 'bold' }}>
                      Downloadable Resources
                    </Typography>
                    {course.files.map((file) => (
                    <Button sx={{ width: '100%', marginBottom: 2 }} variant="contained" component={Link} to={djangoURL + file.fileURL}>
                      {file.fileName}
                    </Button>
                    ))}
                  </CardContent>
                </Card>
              )
            ) : (
              <Card sx={{ marginBottom: 3 }} className="card-element">
                <CardContent>
                  <Typography variant="h6" sx={{ marginBottom: 3, textAlign: 'left', fontWeight: 'bold' }}>
                    {course.document_title}
                  </Typography>
                  <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 25 }}>
                    <Typography sx={{ fontSize: 20, fontWeight: 'bold' }}>Price: </Typography>
                    <Typography sx={{ fontSize: 20, fontWeight: 'bold' }}>${course.price}</Typography>
                  </div>
                  <StripeContainer courseId={id} />
                </CardContent>
              </Card>
            )}
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default MyDocumentDetails;
