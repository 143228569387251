import axios from 'axios'
import djangoURL from './GlobalVariables'

const baseUrl = djangoURL
const AxiosInstance = axios.create({
    baseURL:baseUrl,
    timeout: 5000,
    headers:{
        "Content-Type": "application/json",
        accept: "application/json"
    }

})

export default AxiosInstance