import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import djangoURL from '../GlobalVariables';
import { Button, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

const stripePromise = loadStripe('pk_live_51Q0b5IHcvnqFlwNgJRKhd4UsApprpBViltj80N7zp4kHhxf9ItwNF3hN41GvIK5NEtaBkpMTVRIy9SMpBFSrJ77800Fh2RWnJJ');

const PaymentForm = ({ courseId }) => {
    const stripe = useStripe();
    const elements = useElements();
    const navigate = useNavigate();
    const [clientSecret, setClientSecret] = useState('');
    const [loading, setLoading] = useState(false); // Add loading state
    const token = localStorage.getItem('token'); // Retrieve the token

    if (!token) {
        console.error('No token found');
        return (
            <div>
                <Button sx={{ width: '100%'}} variant="contained" component={Link} to="/login">Login To Pay</Button>
                <Typography>OR</Typography>
                <Button sx={{ width: '100%'}} variant="contained" component={Link} to="/register">Register To Pay</Button>
            </div>
        );
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!stripe || !elements) {
            return;
        }

        const cardElement = elements.getElement(CardElement);

        setLoading(true); // Start loading

        try {
            // Fetch client secret from backend
            const response = await fetch(`${djangoURL}/Academy/create-payment-intent/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${token}`
                },
                body: JSON.stringify({ courseId }),
            });

            if (!response.ok) {
                throw new Error('Failed to fetch client secret');
            }

            const data = await response.json();

            // Ensure client secret is valid
            if (!data.clientSecret || typeof data.clientSecret !== 'string' || !data.clientSecret.startsWith('pi_')) {
                throw new Error('Invalid client secret format');
            }

            // Set client secret state
            setClientSecret(data.clientSecret);

            // Confirm payment intent with Stripe
            const { error, paymentIntent } = await stripe.confirmCardPayment(data.clientSecret, {
                payment_method: {
                    card: cardElement,
                },
            });

            if (error) {
                console.error('[error]', error);
            } else if (paymentIntent.status === 'succeeded') {
                console.log('Payment successful!');
                navigate(`/MyAcademy/${courseId}`);
            }
        } catch (error) {
            console.error('Error during payment confirmation:', error);
        } finally {
            setLoading(false); // Stop loading
        }
    };

    return (
        <form onSubmit={handleSubmit} className="payment-form">
            <CardElement className="card-element" />
            <button type="submit" disabled={!stripe || loading} className="pay-button">
                {loading ? <div className="spinner"></div> : 'Pay'}
            </button>
        </form>
    );
};

const StripeContainer = ({ courseId }) => (
    <Elements stripe={stripePromise}>
        <PaymentForm courseId={courseId} />
    </Elements>
);

export default StripeContainer;
