import React, { useEffect, useState } from 'react';
import { Container, Grid, Card, CardContent, Typography, CardActionArea, CardMedia, Divider, Button } from '@mui/material';
import AxiosInstance from './Axios';
import djangoURL from './GlobalVariables'


const Podcasts = () => {
    const [podcasts, setPodcasts] = useState([]);
    const [loading, setLoading] = useState(true);
  
    useEffect(() => {
      const fetchData = async () => {
        try {
          // Update this headers object with your required headers
          const headers = {
            'Content-Type': 'application/json'
          };
    
          const response = await AxiosInstance.get('/Academy/api/podcasts/', { headers });
          console.log(response.data);
          setPodcasts(response.data);
          setLoading(false);
        } catch (error) {
          console.error('Error fetching podcasts:', error);
          setLoading(false);
        }
      };
    
      fetchData();
    }, []);
    

  return (
    <Container sx={{ paddingTop: 5, paddingBottom: 10 }}>
      <Typography variant="h3" component="h1" gutterBottom sx={{marginBottom: 6}}>
      <span style={{ fontFamily: 'Matura MT Script Capitals'}}>Weber Podcasts</span>
        
      </Typography>
      <Grid container spacing={4}>
        {podcasts.map((podcast) => (
          <Grid item key={podcast.id_} xs={12} sm={6} md={4}> {/* Adjust the xs, sm, and md properties */}
            <Card sx={{ width: '100%', height: '100%' }}>
              <CardActionArea href={`/Podcast/${podcast.id_}`}>
                <CardMedia
                  component="img"
                  height="300"
                  width="100%"
                  image= {djangoURL + podcast.image_url}
                  alt={podcast.podcast_title}
                />
                <CardContent>
                  <Typography sx={{fontSize: "20px", fontWeight:"bold", minHeight:60, textAlign:'left'}}>
                    {podcast.podcast_title}
                  </Typography>
                  <Typography sx={{textAlign:'left', fontSize:"14px", minHeight:60, maxHeight:60, overflow: 'hidden'}}>
                  {podcast.podcast_short_description}
                  </Typography>
                  <Divider sx={{marginTop:2, maringBottom:2,}} />
                  {podcast.is_for_sale ? (
                    podcast.is_purchased ? (
                      <Typography sx={{fontSize: "20px", color:'black', marginTop:1}} color="textSecondary">
                      Purchased
                    </Typography>
                    ) : (
                      <Typography sx={{fontSize: "23px", color:'black', fontWeight:"bold", marginTop:1}} color="textSecondary">
                      ${podcast.price}
                    </Typography>
                    )
                  ) : (
                  <Typography sx={{textAlign:'center', fontSize:"14px", paddingTop:2}}>
                    Date Launch: {podcast.launch_date}
                  </Typography>
                  )}
                  {podcast.is_for_sale ? (
                    podcast.is_purchased ? (
                      <Button sx={{ marginTop: 1 }} variant="contained">View Podcast</Button>
                    ) : (
                      <Button sx={{ marginTop: 1 }} variant="contained">Buy Podcast</Button>
                    ) 
                    ) : (
                      <Button sx={{ marginTop: 1 }} variant="contained">View Podcast</Button>
                      
                    )}

                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default Podcasts;
