import React, { useState } from 'react';
import { Typography, Container, Button, Box, styled, CircularProgress } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import EmailIcon from '@mui/icons-material/Email';
import LoginIcon from '@mui/icons-material/Login';
import AxiosInstance from './Axios'; // Import your Axios instance

const CircleIconContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '120px',
  height: '120px',
  borderRadius: '50%',
  backgroundColor: theme.palette.success.main,
  marginBottom: theme.spacing(4),
  margin: 'auto',
}));

const SuccessPage = () => {
  const location = useLocation();
  const email = location.state?.email; // Retrieve email from state
  const [loading, setLoading] = useState(false);
  const [disableResend, setDisableResend] = useState(false);
  const [countdown, setCountdown] = useState(45);

  const resendVerificationEmail = async () => {
    try {
      setLoading(true);
      setDisableResend(true);
      await AxiosInstance.post(`/Academy/api/resend-verification/${encodeURIComponent(email)}`);

      startCountdown();
    } catch (error) {
        if (error.response && error.response.data && error.response.data.error === 'Already Verify') {
          alert('User is already verified.');
        } else {
          console.error('Error resending verification email:', error);
          alert('Failed to resend verification email.');
        }
      } finally {
        setLoading(false);
      }
  };

  const startCountdown = () => {
    let seconds = 45;
    const countdownInterval = setInterval(() => {
      seconds--;
      setCountdown(seconds);
      if (seconds === 0) {
        clearInterval(countdownInterval);
        setDisableResend(false);
        setCountdown(45);
      }
    }, 1000);
  };

  return (
    <Container maxWidth="xs" sx={{ minHeight: 600, padding: 15 }}>
      <CircleIconContainer>
        <ThumbUpIcon sx={{ fontSize: '5rem', color: '#fff' }} />
      </CircleIconContainer>
      <Typography variant="h4" align="center" gutterBottom sx={{ marginBottom: 2, marginTop: 5 }}>
        Almost Done!
      </Typography>
      <Typography variant="body1" align="center" sx={{ marginBottom: 2 }}>
        Please verify your email first to complete your registration.
      </Typography>
      <Button
        component={Link}
        to="/Login/"
        variant="contained"
        color="primary"
        fullWidth
        sx={{ marginBottom: 2, marginTop: 2, paddingTop: 2, paddingBottom: 2 }}
      >
        <LoginIcon sx={{ marginRight: 1 }} /> Go to Login
      </Button>
      {email && (
        <Button
          onClick={resendVerificationEmail}
          variant="contained"
          color="primary"
          fullWidth
          disabled={disableResend}
          sx={{ marginBottom: 10, paddingTop: 2, paddingBottom: 2 }}
        >
          {loading ? (
            <CircularProgress size={24} color="inherit" sx={{ marginRight: 1 }} />
          ) : (
            <EmailIcon sx={{ marginRight: 1 }} />
          )}
          {disableResend ? `Resend in ${countdown} seconds` : 'Resend Verification Email'}
        </Button>
      )}
    </Container>
  );
};

export default SuccessPage;
