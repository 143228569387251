import React, { useEffect, useState } from 'react';
import { Container, Grid, Card, CardContent, Typography, CardActionArea, CardMedia, Divider, Button, Box } from '@mui/material';
import AxiosInstance from './Axios';
import djangoURL from './GlobalVariables';
import LockPersonIcon from '@mui/icons-material/LockPerson';

const Academy = () => {
  const [courses, setCourse] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchCourseDetails = async () => {
      const token = localStorage.getItem('token'); // Retrieve the token
      if (!token) {
        console.error('No token found');
        return;
      }

      try {
        const response = await AxiosInstance.get('/Academy/api/MyAcademy/', {
          headers: {
            Authorization: `Token ${token}` // Attach the token in the Authorization header
          }
        });
        console.log(response.data);
        setCourse(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching course details:', error);
        setLoading(false);
      }
    };

    fetchCourseDetails();
  }, []); // Add empty dependency array to ensure this runs only once when component mounts

  if (loading) {
    return (
        <Container sx={{ paddingTop: 8, paddingBottom: 10 }}>
            <Card sx={{ width: '100%', height: '100%', paddingtop: 15, paddingBottom: 8}}>
                <CardContent>
                    <Box sx={{paddingTop: 4}}>
                        <LockPersonIcon style={{fontSize: 100, color:'#fcad03'}} />
                    </Box>
                    
                    <Typography variant="h5" component="h1" sx={{marginBottom: 1}}>
                        You don't have permission to access this page.
                    </Typography>
                    <Typography variant="h5" component="h1" gutterBottom sx={{ marginBottom: 5 }}>
                        Kindly Login or Create An Account.
                    </Typography>
                    <Box>
                        <Button sx={{ marginTop: 1 }} variant="contained" href="/login">Login</Button>
                    </Box>
                    <Typography sx={{marginTop: 1}}>OR</Typography>
                    <Box>
                        <Button sx={{ marginTop: 1 }} variant="contained">Register</Button>
                    </Box>
             
                </CardContent>
            </Card>
        </Container>
    );
  }

  return (
    <Container sx={{ paddingTop: 5, paddingBottom: 10 }}>
  <Typography variant="h3" component="h1" gutterBottom sx={{ marginBottom: 6 }}>
    <span style={{ fontFamily: 'Matura MT Script Capitals' }}>My Academy</span>
  </Typography>
  <Grid container spacing={4}>
    {courses.length > 0 ? (
      courses.map((course) => (
        <Grid item key={course.id_} xs={12} sm={6} md={4}>
          <Card sx={{ width: '100%', height: '100%' }}>
            <CardActionArea href={`/MyAcademy/${course.id_}`}>
              <CardMedia
                component="img"
                height="300"
                width="100%"
                image={djangoURL + course.imageURL}
                alt={course.title}
              />
              <CardContent>
                <Typography sx={{ fontSize: "20px", fontWeight: "bold", textAlign: 'left' }}>
                  {course.course_name}
                </Typography>
                <Typography sx={{ textAlign: 'left', fontSize: "14px", minHeight: 50, maxHeight: 50, overflow: 'hidden' }}>
                  {course.course_short_description}
                </Typography>
                <Divider sx={{ marginTop: 2, marginBottom: 2 }} />
                <Button sx={{ marginTop: 1 }} variant="contained">Take Course</Button>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
      ))
    ) : (
      <Grid item xs={12}>
        <Card sx={{ width: '100%', minHeight: '500px'}}>
          <CardContent sx={{ textAlign: 'center' }}>
            <Typography sx={{ fontSize: "20px", fontWeight: "bold", color: '#1976d2', marginTop: 20 }}>
              You don't have any enrolled courses
            </Typography>
            <Button 
              sx={{ marginTop: 2 }} 
              variant="contained" 
              href="/Courses">
              Browse Available Courses
            </Button>
          </CardContent>
        </Card>
      </Grid>
    )}
  </Grid>
</Container>

  );
};

export default Academy;
