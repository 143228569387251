import React, { useState, useEffect } from 'react';
import AxiosInstance from './Axios'; // Import your Axios instance
import { useParams, Link } from 'react-router-dom';
import { Typography, Card, CardContent, Grid, CardMedia, Box, Button } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import djangoURL from './GlobalVariables';
import LockPersonIcon from '@mui/icons-material/LockPerson';
import { styled } from '@mui/system';

const TakeCourse = () => {
  const { id, expandedLectureId } = useParams();
  const [course, setCourse] = useState(null);
  const [loading, setLoading] = useState(true);
  const [expandedIndex, setExpandedIndex] = useState(parseInt(expandedLectureId, 10) || 0);

  const Container = styled('div')({
    '@media (min-width: 1200px)': {
      maxWidth: '100%',
    },
  });

  useEffect(() => {
    const fetchCourseDetails = async () => {
      const token = localStorage.getItem('token'); // Retrieve the token
      if (!token) {
        console.error('No token found');
        return;
      }

      try {
        const response = await AxiosInstance.get(`/Academy/api/take/course/${id}/`, {
          headers: {
            Authorization: `Token ${token}` // Attach the token in the Authorization header
          }
        });
        console.log(response.data)
        setCourse(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching course details:', error);
        setLoading(false);
      }
    };

    fetchCourseDetails();
  }, [id]); // Depend on id since it's used in the URL

  const handleAccordionChange = (index) => {
    setExpandedIndex(index === expandedIndex ? null : index);
  };

  if (loading) {
    return (
        <Container sx={{ paddingTop: 8, paddingBottom: 10, width:'100%' }} className="css-pdaplq-MuiContainer-root">
            <Card sx={{ width: '100%', height: '100%', paddingtop: 15, paddingBottom: 8}}>
                <CardContent>
                    <Box sx={{paddingTop: 4}}>
                        <LockPersonIcon style={{fontSize: 100, color:'#fcad03'}} />
                    </Box>
                    
                    <Typography variant="h5" component="h1" sx={{marginBottom: 1}}>
                        You don't have permission to access this page.
                    </Typography>
                    {/* <Typography variant="h5" component="h1" gutterBottom sx={{ marginBottom: 5 }}>
                        Kindly Login or Create An Account.
                    </Typography>
                    <Box>
                        <Button sx={{ marginTop: 1 }} variant="contained" href="/login">Login</Button>
                    </Box>
                    <Typography sx={{marginTop: 1}}>OR</Typography>
                    <Box>
                        <Button sx={{ marginTop: 1 }} variant="contained">Register</Button>
                    </Box> */}
             
                </CardContent>
            </Card>
        </Container>
    );
  }

  return (
    <Grid container spacing={4} sx={{padding:5}}>
        <Grid item key={course.id} xs={12} sm={6} md={4}>
            <Container>
            
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 1 }}>
            <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
            Course Details
            </Typography>
            <Typography variant="body1">
            Overall Progress: {course.lectureProgress}%
            </Typography>
        
            </Box>
            {course.lectures.map((lecture, index) => (
            <MuiAccordion
              key={index}
              expanded={expandedIndex === index}
              onChange={() => handleAccordionChange(index)}
            >
                <MuiAccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel${index + 1}-content`}
                id={`panel${index + 1}-header`}
                sx={{backgroundColor: '#f6f6f6'}}
                component={Link}
                to={index === 0 ? `/MyAcademy/${course.id}` : `/MyAcademy/${course.id}/Lecture/${lecture.id}`}
                >
                <Typography variant="h6" sx={{color: expandedIndex === index ? 'blue' : 'black'}}>{lecture.lecture_name}</Typography>
                </MuiAccordionSummary>
                <MuiAccordionDetails>
                
                <Typography sx={{textAlign:'left', fontSize:19, paddingLeft: '2%'}}>{lecture.lecture_description}</Typography>
                <ul style={{ paddingLeft: '10%', marginTop: '10px' }}>
                    {lecture.sub_lecture.map((sub_lecture) => (
                   
                   <li key={sub_lecture.id}>
                   <Typography sx={{textAlign:'left', fontSize:18, marginBottom:1}}>{sub_lecture.sub_lecture_title}</Typography>
               </li>
               ))}
           </ul>
           </MuiAccordionDetails>
       </MuiAccordion>
       ))}

        <Box>
          <Button 
                   sx={{marginBottom: 1, marginTop: 2, backgroundColor: '#09ad46',
                    '&:hover': {
                      backgroundColor: '#057531', // Change to the desired hover color
                    }}}
                   variant="contained" 
                   color="secondary" 
                   href={`/Examination/${course.id}`}
                   fullWidth
                 >Take the Examination
              </Button>
          </Box>
       </Container>
   </Grid>
   <Grid item key={course.id_} xs={12} sm={4} md={8}>
       <Container>
           <Card sx={{ backgroundColor: '#f1f7ff', marginBottom: 3 }}>
               <CardContent>
                   <Grid container>
                       <Grid item xs={12} sm={6}>
                           <CardMedia
                               component="img"
                               alt="Image"
                               height="500"
                               image={djangoURL + course.imageURL}
                               title="Image Title"
                           />
                       </Grid>
                       <Grid
                           item
                           xs={12}
                           sm={6}
                           sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}
                       >
                           <Typography variant="h3" component="h2" sx={{ textAlign: 'center' }}>
                               {course.course_name}
                           </Typography>
                           <Typography variant="body1" component="p" sx={{ textAlign: 'center' }}>
                               {course.course_short_description}
                           </Typography>
                       </Grid>
                   </Grid>
               </CardContent>
           </Card>
           <Card sx={{marginBottom: 3 }}>
               <CardContent>
                   <Typography variant="h5" sx={{marginBottom: 1, textAlign:'left', fontWeight: 'bold'}}>Course Description:</Typography>
                   <Typography sx={{textAlign:'left', fontSize: 18}}>
                       {course.course_full_description}
                   </Typography>
               </CardContent>
           </Card>
       </Container>
   </Grid>
</Grid>
);
};

export default TakeCourse;
