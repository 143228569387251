import React, { useState, useEffect } from 'react';
import AxiosInstance from './Axios'; // Import your Axios instance
import { useParams, useNavigate } from 'react-router-dom'; // Import useNavigate
import { Typography, Card, CardContent, Grid, CardMedia, Box } from '@mui/material';
import { styled } from '@mui/system';
import djangoURL from './GlobalVariables';
import StripeContainer from './forms/PaymentPodcast';

const PodcastDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate(); // Initialize navigate hook
  const [podcast, setPodcast] = useState(null);
  const [loading, setLoading] = useState(true);
  
  const token = localStorage.getItem('token'); // Retrieve the token

  const Container = styled('div')({
    '@media (min-width: 1200px)': {
      maxWidth: '100%',
    },
    padding: 5,
  });

  useEffect(() => {
    if (!token) {
      navigate('/login'); // Redirect to login page if token is not present
      return;
    }

    const fetchPodcastDetails = async () => {
      try {
        const headers = {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`,
        };

        const response = await AxiosInstance.get(`/Academy/api/podcast/${id}/`, { headers });
        console.log('API Response:', response.data);
        console.log(id)
        setPodcast(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching podcast details:', error);
        setLoading(false);
      }
    };

    fetchPodcastDetails();
  }, [id, token, navigate]);

  if (loading) {
    return <Typography>Loading...</Typography>;
  }

  if (!podcast) {
    return <Typography>No podcast details available.</Typography>;
  }

  return (
    <Container>
      <Box
        sx={{
          alignItems: 'center',
          minHeight: '100vh',
          textAlign: 'center',
        }}
      >
        <Grid container spacing={5} justifyContent="center">
          <Grid item xs={12} md={8}>
            <Card sx={{ marginBottom: 3 }}>
              <CardContent>
                <Typography variant="h4" component="h2" sx={{ textAlign: 'center' }}>
                  {podcast.podcast_title || 'Title not available'}
                </Typography>
                <Typography variant="body1" component="p" sx={{ textAlign: 'center' }}>
                  {podcast.podcast_short_description || 'Short description not available'}
                </Typography>
              </CardContent>
            </Card>

            <Card sx={{ marginBottom: 3, marginTop: 4 }}>
              <CardContent>
                <Typography variant="h5" sx={{ marginBottom: 1, textAlign: 'left', fontWeight: 'bold' }}>
                  Podcast Description:
                </Typography>
                <Typography sx={{ textAlign: 'left', fontSize: 18 }}>
                  {podcast.podcast_full_description || 'Full description not available'}
                </Typography>
              </CardContent>
            </Card>

            {podcast.is_for_sale && !podcast.is_purchased && (
              <Grid item xs={12} md={6}>
                <Card sx={{ marginBottom: 3 }}>
                  <CardContent>
                    <Typography variant="h6" sx={{ marginBottom: 3, textAlign: 'left', fontWeight: 'bold' }}>
                      {podcast.podcast_title}
                    </Typography>
                    <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 25 }}>
                      <Typography sx={{ fontSize: 20, fontWeight: 'bold' }}>Price: </Typography>
                      <Typography sx={{ fontSize: 20, fontWeight: 'bold' }}>${podcast.price}</Typography>
                    </div>
                    <StripeContainer courseId={id} />
                  </CardContent>
                </Card>
              </Grid>
            )}
          </Grid>
          {podcast.is_for_sale && podcast.is_purchased && (
          <Grid item xs={12} md={8}>
            {podcast.podcast_audio ? (
              <Card sx={{ marginBottom: 3 }}>
                <CardContent>
                  <CardMedia
                    component="audio"
                    src={djangoURL + (podcast.podcast_audio || '')}
                    autoPlay
                    controls
                    controlsList="nodownload"
                    sx={{ width: '100%' }} 
                  />
                </CardContent>
              </Card>
            ) : podcast.podcast_video ? (
              <Card sx={{ marginBottom: 3 }}>
                <CardContent>
                  <CardMedia
                    component="video"
                    src={djangoURL + (podcast.podcast_video || '')}
                    autoPlay
                    controls
                    controlsList="nodownload"
                    sx={{ width: '100%' }}
                  />
                </CardContent>
              </Card>
            ) : null}
          </Grid>
          )}
          { !podcast.is_for_sale && (
          <Grid item xs={12} md={8}>
            {podcast.podcast_audio ? (
              <Card sx={{ marginBottom: 3 }}>
                <CardContent>
                  <CardMedia
                    component="audio"
                    src={djangoURL + (podcast.podcast_audio || '')}
                    autoPlay
                    controls
                    controlsList="nodownload"
                    sx={{ width: '100%' }}
                  />
                </CardContent>
              </Card>
            ) : podcast.podcast_video ? (
              <Card sx={{ marginBottom: 3 }}>
                <CardContent>
                  <CardMedia
                    component="video"
                    src={djangoURL + (podcast.podcast_video || '')}
                    autoPlay
                    controls
                    controlsList="nodownload"
                    sx={{ width: '100%' }}
                  />
                </CardContent>
              </Card>
            ) : null}
          </Grid>
        )}

        </Grid>
      </Box>
    </Container>
  );
};

export default PodcastDetails;
