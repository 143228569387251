import React, { useState } from 'react';
import { TextField, Button, Typography, Container, IconButton, InputAdornment, CircularProgress } from '@mui/material';
import { styled } from '@mui/system';
import AxiosInstance from './Axios';
import { useNavigate, Link } from 'react-router-dom';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const StyledForm = styled('form')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginTop: theme.spacing(4),
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

const LoginForm = ({ onLogin }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false); // State to toggle password visibility
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false); // State to manage loading state
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent the default form submission

    try {
      setLoading(true); // Set loading to true to disable the button and show loading indicator
      const response = await AxiosInstance.post('/Academy/api/login/', { username, password });
      // Assuming successful login returns a token
      localStorage.setItem('token', response.data.token);
      // Call onLogin function to update authentication status in App
      onLogin(); // Update authentication status

      navigate('/');
    } catch (error) {
      // Handle login error
      console.error('Login failed:', error);
      setError('Login failed. Please check your credentials and try again.');
    } finally {
      setLoading(false); // Set loading to false after login attempt is finished
    }
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Container maxWidth="xs" sx={{ minHeight: 600 }}>
      <Typography variant="h4" align="center" gutterBottom sx={{ marginBottom: 2, marginTop: 10 }}>
        Login
      </Typography>
      <StyledForm onSubmit={handleSubmit}>
        <StyledTextField
          label="Username"
          variant="outlined"
          fullWidth
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          sx={{ marginBottom: 2 }}
        />
        <StyledTextField
          label="Password"
          type={showPassword ? 'text' : 'password'} // Toggle between text and password type
          variant="outlined"
          fullWidth
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleTogglePasswordVisibility}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          sx={{ marginBottom: 2 }}
        />
        {error && (
          <Typography variant="body2" color="error" align="center" gutterBottom>
            {error}
          </Typography>
        )}
         <Link component={Link} to="/Register/"> Need an Account?</Link>
        <StyledButton
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          disabled={loading} // Disable button when loading is true
          sx={{ marginBottom: 20, marginTop: 2, paddingTop: 2, paddingBottom: 2 }}
        >
          {loading ? <CircularProgress size={24} color="inherit" /> : 'Login'}
        </StyledButton>
      </StyledForm>
    </Container>
  );
};

export default LoginForm;
