import React, { useEffect, useState } from 'react';
import { Container, Grid, Card, CardContent, Typography, CardActionArea, CardMedia, Divider, Button } from '@mui/material';
import AxiosInstance from './Axios';
import djangoURL from './GlobalVariables'


const Courses = () => {
    const [courses, setCourses] = useState([]);
    const [loading, setLoading] = useState(true);
  
    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await AxiosInstance.get('/Academy/api/courses/');
          console.log(response.data)
          setCourses(response.data);
          setLoading(false);
        } catch (error) {
          console.error('Error fetching courses:', error);
          setLoading(false);
        }
      };
  
      fetchData();
    }, []);

  return (
    <Container sx={{ paddingTop: 5, paddingBottom: 10 }}>
      <Typography variant="h3" component="h1" gutterBottom sx={{marginBottom: 6}}>
      <span style={{ fontFamily: 'Matura MT Script Capitals'}}>Weber Courses</span>
        
      </Typography>
      <Grid container spacing={4}>
        {courses.map((course) => (
          <Grid item key={course.id_} xs={12} sm={6} md={4}> {/* Adjust the xs, sm, and md properties */}
            <Card sx={{ width: '100%', height: '100%' }}>
              <CardActionArea href={`/Course/${course.id_}`}>
                <CardMedia
                  component="img"
                  height="300"
                  width="100%"
                  image= {djangoURL + course.image_url}
                  alt={course.title}
                />
                <CardContent>
                  <Typography sx={{fontSize: "20px", fontWeight:"bold", minHeight:60,  textAlign:'left'}}>
                    {course.course_name}
                  </Typography>
                  <Typography sx={{textAlign:'left', fontSize:"14px", minHeight:50, maxHeight:50, overflow: 'hidden'}}>
                  {course.course_short_description}
                  </Typography>
                  <Divider sx={{marginTop:2, maringBottom:2,}} />
                  <Typography sx={{fontSize: "23px", color:'black', fontWeight:"bold", marginTop:1}} color="textSecondary">
                   ${course.price}
                  </Typography>
                  <Button sx={{marginTop:1}} variant="contained">Buy Course</Button>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default Courses;
