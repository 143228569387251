import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import AxiosInstance from './Axios'; // Import your Axios instance
import { Button, Container, CircularProgress } from '@mui/material';
import { useNavigate, Link } from 'react-router-dom';
import MyTextFields from './forms/MyTextField';

const RegistrationForm = () => {
    const { handleSubmit, control, watch, setError, formState: { errors } } = useForm();
    const [loading, setLoading] = useState(false); // State to track loading state
    const password = watch('password', '');
    const navigate = useNavigate();

    const onSubmit = async data => {
        setLoading(true); // Set loading to true when submitting form
        try {
            const res = await AxiosInstance.post('/Academy/api/register/', data);
            console.log(data)
            navigate('/Success/', { state: { email: data.email } });
        } catch (err) {
            console.log(err)
            const errorData = err.response.data;
            if (errorData.username) {
                setError('username', {
                    type: 'manual',
                    message: errorData.username[0],
                });
            }
            if (errorData.email) {
                setError('email', {
                    type: 'manual',
                    message: errorData.email[0],
                });
            }
            if (errorData.password) {
                setError('password', {
                    type: 'manual',
                    message: errorData.password[0],
                });
            }
            // Handle other fields as necessary
        } finally {
            setLoading(false); // Set loading back to false after request completes
        }
    };
    
    return (
        <Container maxWidth="xs" sx={{ minHeight: 600, padding: 5 }}>
            <h1>Create an Account</h1>
            <form onSubmit={handleSubmit(onSubmit)}>
                <MyTextFields 
                    name="first_name" 
                    control={control} 
                    label="First Name" 
                    placeholder="Enter your first name" 
                    width="100%" 
                />
                <MyTextFields 
                    name="last_name" 
                    control={control} 
                    label="Last Name" 
                    placeholder="Enter your last name" 
                    width="100%" 
                />
                <MyTextFields 
                    name="email" 
                    control={control} 
                    label="Email" 
                    placeholder="Enter your email" 
                    width="100%" 
                    rules={{
                        required: 'Email is required',
                        pattern: {
                            value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                            message: 'Enter a valid email address'
                        }
                    }}
                />
                <MyTextFields 
                    name="username" 
                    control={control} 
                    label="Username" 
                    placeholder="Enter your username" 
                    width="100%" 
                    rules={{ required: 'Username is required' }}
                />
                
                <MyTextFields 
                    name="password" 
                    control={control} 
                    label="Password" 
                    placeholder="Enter your password" 
                    width="100%" 
                    type="password"
                    rules={{ required: 'Password is required' }}
                />
                
                <MyTextFields 
                    name="confirm_password" 
                    control={control} 
                    label="Confirm Password" 
                    placeholder="Confirm your password" 
                    width="100%" 
                    type="password"
                    rules={{
                        required: 'Confirm password is required',
                        validate: value =>
                            value === password || 'Passwords do not match'
                    }}
                />
                <Link component={Link} to="/Login/"> Already have an Account?</Link>
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    disabled={loading} // Disable button when loading
                    sx={{ marginBottom: 10, marginTop: 2, paddingTop: 2, paddingBottom: 2 }}
                >
                    {loading ? ( // Show loading indicator if loading
                        <CircularProgress size={24} color="inherit" />
                    ) : (
                        'Register'
                    )}
                </Button>
            </form>
        </Container>
    );
};

export default RegistrationForm;
