
import { Container, Grid, Typography, CardMedia, Box} from '@mui/material';
import djangoURL from './GlobalVariables';

const About = () => {
    

  return (
    <Container sx={{ paddingTop: 5, paddingBottom: 10 }}>
      <Typography variant="h3" component="h1" gutterBottom sx={{marginBottom: 6}}>
      <span style={{ fontFamily: 'Matura MT Script Capitals'}}>About Us</span>
        
      </Typography>
      <Typography sx={{fontSize:30, textAlign:'center', marginBottom: 4}}>Silvia Aninye RN, AS, CDP, CDSGF, CADDCT, CLNC</Typography>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <CardMedia
            component="img"
            alt="Image"
            image={djangoURL + '/static/image/silvia.webp'}
            title="Image Title"
            style={{ width: '400px', height: '500px' }}
            />
    </Box>
 
    <Box sx={{marginTop:5}}>
        <Typography sx={{marginBottom:3}}>
        My skill-set and industry expertise are comprehensive and up-to-date. I make it my top priority to see that all jobs are done well and efficiently. My career has also seen its share of achievements; while working as a Legal Nurse Consultant at Weber Legal Nurse Consulting Inc., I have helped a lot of attorneys find testifying experts for different specialties to help support their individual cases.
        </Typography>
        <Typography sx={{marginBottom:3}}>
        I have learned and accomplished a great deal in my 8 years of Legal Nursing Consultant. One of my proudest moments to date occurred while I was an RN at LAC-USC. In this capacity, I was responsible for making sure that the language on Vaccination protocol was legally correct and comprehensive. On one occasion, I helped educate the patients on the legal language on

        Silvia Aninye
        vaccination protocol, which led to a positive outcome because the patient was allergic to eggs and as such could not take the flu vaccine.
        </Typography>

        <Typography sx={{marginBottom:3}}>
        During my time working at Weber Legal Nurse Consulting Inc. as CEO, I have learned a great deal and achieved a number of goals. One of my highest achievements was developing a large network of testifying experts and helping clients develop their protocols to meet industry standards. This led to clients being prepared for state inspections, which in turn helped the reputation of the companies.
        </Typography>

        <Typography sx={{marginBottom:3}}>
        In my recent capacity as CEO for Weber Legal Nursing Consulting Inc., I was able to significantly expand my skill-set. My responsibilities for going over medical malpractice liability cases caused me to develop my research skills and my legal and medical malpractice skills in particular.
        </Typography>

        <Typography sx={{marginBottom:3}}>
        I am motivated, task-oriented, and efficient, and my knowledge of the Personal Injury and Worker’s Compensation industry is comprehensive and current. While working as Medical Case Manager at Crawford and Company I was responsible for field case management duties. I also have been able to use my expertise to help the company remain within the guidelines of most laws and protocols and helped prevent legal issues with clients during my time there, The company has saved a lot of money in costs being able to have a Registered Nurse who is also qualified to work as a Legal Nurse Consultant.
        </Typography>

        <Typography sx={{marginBottom:3}}>
        My enthusiasm and commitment to excellence have served me well. I have extensive experience with my medical experience and Legal Nurse Consulting, having had the chance to significantly develop my research and investigative skills while working as a Legal Nurse Consultant at Weber Legal Nurse Consulting Inc.
        </Typography>

        <Typography sx={{marginBottom:3}}>
        I am an executive Advisory Cloud Network advisor.
        </Typography>

        
    </Box>

    <Box sx={{ display: 'block', textAlign: 'left' }}>
    <Typography sx={{fontSize:25, textAlign:'left', marginBottom: 4}}>EDUCATION</Typography>
    <h4>March 2014 to Present</h4>
    <ul>
        <li>Certified Legal Nurse Consultant</li>
    </ul>
    <h4>August 2009 to August 2011</h4>
    <ul>
        <li>Mount St Mary’s College, Associate Degree in Nursing. Registered Nurse</li>
        <li>Clinical experience at various Los Angeles Hospitals in Med Surgical Units & Pediatrics units</li>
    </ul>
    <h4>July 2004</h4>
    <ul>
        <li>Certified Nursing Assistant and Home Health certification in California</li>
    </ul>
    <h4>September 1994</h4>
    <ul>
        <li>Paralegal Legal Certification, Kennesaw State University, Diploma</li>
    </ul>
    <h4>June 1987</h4>
    <ul>
        <li>High School Diploma, Federal Government College, Nigeria</li>
    </ul>

    <Typography sx={{fontSize:25, textAlign:'left', marginBottom: 4, marginTop:5}}>LICENSES</Typography>
    <ul>
        <li>Registered Nurse License</li>
        <li>Certified Nursing Assistant Certification. Home Health Certification</li>
        <li>Paralegal Certification</li>
    </ul>
    <Typography sx={{fontSize:25, textAlign:'left', marginBottom: 4, marginTop:5}}>CERTIFICATIONS</Typography>
    <ul>
        <li>American Heart saver certification</li>
        <li>BLS certification</li>
        <li>ACLS certification</li>
        <li>Hospital Fire and Life Safety Certification</li>
        <li>Management of Aggressive Behavior</li>
        <li>ECG & Pharmacology certification Community Emergency Response Team (CERT) Certification</li>
        <li>German as a Foreign Language Translation Certification</li>
        <li>Medical Terminology Certification (DCM Instructional Systems)</li>
        <li>Certified Legal Nurse Consultant Certification</li>
        <li>Certified Alzheimer’s Disease and Dementia Care Trainer CADDCT</li>
        <li>Certified Dementia Support Group Facilitator CDSGF</li>
    </ul>
    </Box>


    </Container>
  );
};

export default About;
