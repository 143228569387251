import React, { useState, useEffect, useRef } from 'react';
import AxiosInstance from './Axios'; // Import your Axios instance
import { useParams, Link } from 'react-router-dom';
import { Typography, Card, CardContent, Grid, CardMedia, Box, Button } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import djangoURL from './GlobalVariables';
import LockPersonIcon from '@mui/icons-material/LockPerson';
import { styled } from '@mui/system';


const LectureDetails = () => {
  const { id1, id2 } = useParams();
  const [course, setCourse] = useState(null);
  const [loading, setLoading] = useState(true);
  const videoRef = useRef(null); // Create a reference for the video element
  const [expandedIndex, setExpandedIndex] = useState(0); // Initialize with 0, assuming it's the default expanded accordion

  const handleVideoEnd = () => {
    console.log('Video has ended');
    // Add any additional logic here, such as updating state or notifying the server
  };

  const handleTimeUpdate = async (event) => {
    const token = localStorage.getItem('token');
    if (!token) {
      console.error('No token found');
      return;
    }
    const videoElement = videoRef.current;
    // if (videoRef.current.currentTime >= 5 && videoRef.current.currentTime < 6) {
    if (videoElement.currentTime >= videoElement.duration * 0.8 && videoElement.currentTime < videoElement.duration * 0.8 + 1) {
      console.log('Progress Saving');
      try {
        await fetch(`${djangoURL}/Academy/api/course/${id1}/lecture/${id2}/update/progress/`, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Token ${token}`
          }
      });
      } catch (error) {
        console.error(error);
      }
      videoRef.current.removeEventListener('timeupdate', handleTimeUpdate);
    }
  };

  useEffect(() => {
    const videoElement = videoRef.current;

    if (videoElement) {
      videoElement.addEventListener('timeupdate', handleTimeUpdate);
    }

    return () => {
      if (videoElement) {
        videoElement.removeEventListener('timeupdate', handleTimeUpdate);
      }
    };
  }, [course]); // Add dependency to ensure the event listener is reattached when the video source changes

  const Container = styled('div')({
    '@media (min-width: 1200px)': {
      maxWidth: '100%',
    },
  });

  useEffect(() => {
    const fetchCourseDetails = async () => {
      const token = localStorage.getItem('token'); // Retrieve the token
      if (!token) {
        console.error('No token found');
        return;
      }

      try {
        const response = await AxiosInstance.get(`/Academy/api/course/${id1}/lecture/${id2}/`, {
          headers: {
            Authorization: `Token ${token}` // Attach the token in the Authorization header
          }
        });
        console.log(response.data);
        setCourse(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching course details:', error);
        setLoading(true);
      }
    };

    fetchCourseDetails();
  }, [id1, id2]); // Depend on id1 and id2 since they are used in the URL

  const handleAccordionChange = (index) => {
    setExpandedIndex(index === expandedIndex ? null : index);
  };

  if (loading) {
    return (
      <Container sx={{ paddingTop: 8, paddingBottom: 10, width: '100%' }} className="css-pdaplq-MuiContainer-root">
        <Card sx={{ width: '100%', height: '100%', paddingTop: 15, paddingBottom: 8 }}>
          <CardContent>
            <Box sx={{ paddingTop: 4 }}>
              <LockPersonIcon style={{ fontSize: 100, color: '#fcad03' }} />
            </Box>
            <Typography variant="h5" component="h1" sx={{ marginBottom: 1, paddingBottom: 10 }}>
              You don't have permission to access this page.
            </Typography>
            {/* <Typography variant="h5" component="h1" gutterBottom sx={{ marginBottom: 5 }}>
              Kindly Login or Create An Account.
            </Typography>
            <Box>
              <Button sx={{ marginTop: 1 }} variant="contained" href="/login">Login</Button>
            </Box>
            <Typography sx={{ marginTop: 1 }}>OR</Typography>
            <Box>
              <Button sx={{ marginTop: 1 }} variant="contained">Register</Button>
            </Box> */}
          </CardContent>
        </Card>
      </Container>
    );
  }

  return (
    <Grid container spacing={4} sx={{ padding: 5 }}>
      <Grid item key={course.id} xs={12} sm={6} md={4}>
        <Container>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 1 }}>
        <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
          Course Details
        </Typography>
        <Typography variant="body1">
          Overall Progress: {course.lectureProgress}%
        </Typography>
    
        </Box>
          {course.lectures.map((lecture, index) => (
            <MuiAccordion
              key={lecture.id}
              expanded={lecture.id === id2}
              onChange={() => handleAccordionChange(index)}
            >
              <MuiAccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel${lecture.id + 1}-content`}
                id={`panel${lecture.id + 1}-header`}
                sx={{ backgroundColor: '#f6f6f6' }}
                component={Link}
                to={index === 0 ? `/MyAcademy/${id1}` : `/MyAcademy/${course.id}/Lecture/${lecture.id}`}
                key={`accordion-summary-${lecture.id}`}
              >
                <Typography variant="h6" sx={{ color: lecture.id === id2 ? 'blue' : 'black' }}>{lecture.lecture_name}</Typography>
              </MuiAccordionSummary>
              <MuiAccordionDetails>
                <Typography sx={{ textAlign: 'left', fontSize: 19, paddingLeft: '2%' }}>{lecture.lecture_description}</Typography>
                <ul style={{ paddingLeft: '10%', marginTop: '10px' }}>
                  {lecture.sub_lecture.map((sub_lecture) => (
                    <li key={sub_lecture.id}>
                      <Typography sx={{ textAlign: 'left', fontSize: 18, marginBottom: 1 }}>{sub_lecture.sub_lecture_title}</Typography>
                    </li>
                  ))}
                </ul>
                <Typography sx={{ color: 'black', fontSize: 18, textAlign:'left', marginBottom: 1}}>
                  Downloadable Resources:
                  
                </Typography>
                {lecture.attachments.map((attachment) => (
                  <div>
                   <Button 
                   sx={{marginBottom: 1}}
                   variant="contained" 
                   color="primary" 
                   href={djangoURL + attachment.file_url}
                   target="_blank" // Optional: opens the link in a new tab
                   rel="noopener noreferrer" // Recommended when using target="_blank" for security reasons
                   fullWidth
                 >
                  {attachment.file_name}
                  </Button>
                  </div>
                  ))}
              </MuiAccordionDetails>
            </MuiAccordion>
          ))}
          <Box>
          <Button 
                   sx={{marginBottom: 1, marginTop: 2, backgroundColor: '#09ad46',
                    '&:hover': {
                      backgroundColor: '#057531', // Change to the desired hover color
                    }}}
                   variant="contained" 
                   color="secondary" 
                   href={`/Examination/${id1}`}
                   fullWidth
                 >Take the Examination
              </Button>
          </Box>
        </Container>
      </Grid>
      <Grid item key={course.id + course.id} xs={12} sm={4} md={8}>
        <Container>
          <Card sx={{ marginBottom: 3 }}>
            <CardContent>
              <CardMedia
                component='video'
                src={djangoURL + course.videoURL}
                autoPlay
                controls
                controlsList="nodownload"
                onEnded={handleVideoEnd}
                ref={videoRef} // Attach the reference to the video element
              />
            </CardContent>
          </Card>

          <Card sx={{ marginBottom: 3 }}>
            <CardContent>
              <Typography variant="h5" sx={{ marginBottom: 1, textAlign: 'left', fontWeight: 'bold' }}>Course Description:</Typography>
              <Typography sx={{ textAlign: 'left', fontSize: 18 }}>
                {course.lecture_description}
              </Typography>
            </CardContent>
          </Card>
        </Container>
      </Grid>
    </Grid>
  );
};

export default LectureDetails;
