import React, { useState } from 'react';
import './App.css';
import { Routes, Route } from 'react-router-dom';
import Home from './components/Home';
import Academy from './components/Academy';
import Main from './components/Main';
import LoginForm from './components/Login';
import Courses from './components/Courses';
import CourseDetail from './components/CourseDetails';
import TakeCourse from './components/AcademyTakeCourse';
import LectureDetails from './components/LectureDetails';
import SuccessPage  from './components/Success';
import RegistrationForm from './components/Registration';
import Exam from './components/CourseExamination';
import Podcasts from './components/PodcastList';
import ProdcastDetails from './components/PodcastDetails';
import Documents from './components/Documents';
import DocumentDetails from './components/DocumentDetails';
import MyDocs from './components/MyDocuments';
import MyDocumentDetails from './components/MyDocumentsDetails';
import Books from './components/WeberBooks';
import About from './components/About';
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsOfService from './components/TermsOfService';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(localStorage.getItem('token') !== null);

  const handleLogin = () => {
    setIsLoggedIn(true);
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    setIsLoggedIn(false);
  };

  return (
    <div className="App">
      <Main
        content={
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/About/" element={<About />} />
            <Route path="/Books/" element={<Books />} />
            <Route path="/Podcasts/" element={<Podcasts />} />
            <Route path="/Podcast/:id" element={<ProdcastDetails />} />
            <Route path="/Courses/" element={<Courses />} />
            <Route path="/Course/:id" element={<CourseDetail />} />
            <Route path="/Documents/" element={<Documents />} />
            <Route path="/Document/:id" element={<DocumentDetails />} />
            <Route path="/MyDocuments/:id" element={<MyDocumentDetails />} />
            <Route path="/MyDocuments/" element={<MyDocs />} />
            <Route path="/MyAcademy/" element={<Academy />} />
            <Route path="/MyAcademy/:id" element={<TakeCourse />} />
            <Route path="/Examination/:id" element={<Exam />} />
            <Route path="/MyAcademy/:id1/Lecture/:id2/" element={<LectureDetails />} />
            <Route path="/Login/" element={<LoginForm onLogin={handleLogin} />} />
            <Route path="/Register/" element={ <RegistrationForm /> } />
            <Route path="/Success/" element={<SuccessPage/>} />
            <Route path="/PrivacyPolicy/" element={<PrivacyPolicy/>} />
            <Route path="/TermsOfService/" element={<TermsOfService/>} />
          </Routes>
        }
        isLoggedIn={isLoggedIn}
        handleLogout={handleLogout}
      />
    </div>
  );
}

export default App;
