import React from 'react';
import { Container, Grid, Typography, List, ListItem, ListItemText, ListItemIcon } from '@mui/material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'; // Bullet icon

const PrivacyPolicy = () => {
  return (
    <Container sx={{ paddingTop: 5, paddingBottom: 10 }}>
      <Typography variant="h3" component="h1" gutterBottom sx={{ marginBottom: 6, fontFamily: 'Matura MT Script Capitals' }}>
        Privacy Policy
      </Typography>
      <Grid container spacing={4} sx={{textAlign:'left'}}> 
        <Grid item xs={12}>
          <Typography variant="h5" component="h2" gutterBottom>
            1. Introduction
          </Typography>
          <Typography paragraph>
            Welcome to Weber Online Academy. We are committed to safeguarding the privacy of our students, participants, and visitors, especially with regard to personal information related to Defense Medical Examinations (DME). This Privacy Policy outlines the types of personal information we collect, how we use it, and the measures we take to protect it.
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h5" component="h2" gutterBottom>
            2. Information We Collect
          </Typography>
          <Typography paragraph>
            We may collect the following personal information when you register for our DME-related courses, interact with our services, or provide information for educational purposes:
          </Typography>
          <List>
            <ListItem>
              <ListItemIcon>
                <FiberManualRecordIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Full name and contact information (email, phone number)" />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <FiberManualRecordIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Medical and health-related information required for Defense Medical Examination training" />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <FiberManualRecordIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Defense-related records or documentation relevant to course participation" />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <FiberManualRecordIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Payment and billing information" />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <FiberManualRecordIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Other personal information that you voluntarily provide during course registration or participation" />
            </ListItem>
          </List>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h5" component="h2" gutterBottom>
            3. How We Use Your Information
          </Typography>
          <Typography paragraph>
            The personal information we collect is used to:
          </Typography>
          <List>
            <ListItem>
              <ListItemIcon>
                <FiberManualRecordIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Facilitate your enrollment and participation in our DME-related courses" />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <FiberManualRecordIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Provide medical and defense-related educational materials and resources" />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <FiberManualRecordIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Ensure compliance with defense medical examination regulations" />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <FiberManualRecordIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Communicate with you regarding course updates, scheduling, and certifications" />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <FiberManualRecordIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Process payments and manage your billing" />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <FiberManualRecordIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Meet legal and regulatory obligations related to medical and defense education" />
            </ListItem>
          </List>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h5" component="h2" gutterBottom>
            4. Sharing of Your Information
          </Typography>
          <Typography paragraph>
            We take your privacy seriously and will only share your personal information under the following circumstances:
          </Typography>
          <List>
            <ListItem>
              <ListItemIcon>
                <FiberManualRecordIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="With authorized personnel involved in course administration and instruction" />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <FiberManualRecordIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="With defense or medical agencies when required by law or to comply with legal regulations" />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <FiberManualRecordIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="With service providers that assist with payment processing and course delivery" />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <FiberManualRecordIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="In cases where disclosure is necessary for legal, regulatory, or safety reasons" />
            </ListItem>
          </List>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h5" component="h2" gutterBottom>
            5. Security of Your Information
          </Typography>
          <Typography paragraph>
            We take appropriate technical and organizational measures to protect the confidentiality and integrity of your personal information. However, while we strive to safeguard your data, please note that no system is completely secure, and we cannot guarantee absolute security.
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h5" component="h2" gutterBottom>
            6. Your Rights
          </Typography>
          <Typography paragraph>
            You have certain rights regarding the personal information we hold about you, including the right to:
          </Typography>
          <List>
            <ListItem>
              <ListItemIcon>
                <FiberManualRecordIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Access and request copies of your personal data" />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <FiberManualRecordIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Request corrections to inaccurate or incomplete data" />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <FiberManualRecordIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Request the deletion of your personal data, subject to regulatory requirements" />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <FiberManualRecordIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Object to or restrict the processing of your data in certain circumstances" />
            </ListItem>
          </List>
          <Typography paragraph>
            To exercise these rights, please contact us at silvia@weberlegalnurse.com.
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h5" component="h2" gutterBottom>
            7. Data Retention
          </Typography>
          <Typography paragraph>
            We retain your personal information for as long as necessary to fulfill the purposes outlined in this Privacy Policy, including legal, accounting, or reporting requirements. Once your personal data is no longer required, we will securely delete or anonymize it.
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h5" component="h2" gutterBottom>
            8. Changes to This Privacy Policy
          </Typography>
          <Typography paragraph>
            We reserve the right to update this Privacy Policy from time to time. Any changes will be posted on this page, and we encourage you to review the policy periodically to stay informed.
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h5" component="h2" gutterBottom>
            9. Contact Us
          </Typography>
          <Typography paragraph>
            If you have any questions or concerns about this Privacy Policy or our data handling practices, please contact us at silvia@weberlegalnurse.com.
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
};

export default PrivacyPolicy;
