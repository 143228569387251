
import { Container, Grid, Typography} from '@mui/material';


const Books = () => {
    

  return (
    <Container sx={{ paddingTop: 5, paddingBottom: 10 }}>
      <Typography variant="h3" component="h1" gutterBottom sx={{marginBottom: 6}}>
      <span style={{ fontFamily: 'Matura MT Script Capitals'}}>Weber Books</span>
        
      </Typography>
      <Grid container spacing={4}>
        <Grid xs={12} sm={6} md={4}>
            <iframe 
            type="text/html" 
            sandbox="allow-scripts allow-same-origin allow-popups" 
            width="336" 
            height="550" 
            frameborder="0" 
            allowfullscreen 
            style={{ maxWidth: '100%' }} 
            title="Defense Medical Exams Made Easy"
            src="https://read.amazon.com/kp/card?asin=B0CTHPHJC1&preview=inline&linkCode=kpe&ref_=kip_embed_taf_preview_4677YMTVZ2CGN607WZG8&tag=gcore03-20" >
            </iframe>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Books;
